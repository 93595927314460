import { Status } from 'types';
import Language from 'constants/Language';

export interface SubscriptionResponse {
  status: Status;
  responseMsg: string;
}

export const subscribe = (
  emailAddress: string,
  subscribeURL?: string
): Promise<SubscriptionResponse> => {
  return new Promise(async (resolve) => {
    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: emailAddress,
          subscribeURL,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // On subscription success, inject the geq.suppress() script
        const script = document.createElement('script');
        script.text = 'geq.suppress();';
        document.body.appendChild(script);

        resolve({
          status: Status.FULFILLED,
          responseMsg: Language.t('Global.emailSubscribeSuccessMessage'),
        });
      } else {
        resolve({
          status: Status.REJECTED,
          responseMsg: data.error || 'Subscription failed',
        });
      }
    } catch (error) {
      resolve({
        status: Status.REJECTED,
        responseMsg:
          error instanceof Error ? error.message : 'An unknown error occurred',
      });
    }
  });
};
